<template>
  <default-layout :searchFunction="loadData" searchParamString="q">
    <v-container id="agent-office-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <agent-office-list
        :items="agentOffices"
        :meta="meta"
        :permissions="permissions"
        @changePage="changePage"
      ></agent-office-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const AgentOfficeList = () => import('@/components/master-data/agent-office/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    AgentOfficeList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.agentOffice.permissions,
      agentOffices: (state) => state.agentOffice.agentOffices,
      meta: (state) => state.agentOffice.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('agentOffice/RESET_DEFAULT');
      const response = await this.$store.dispatch('agentOffice/getInitData', query);
      console.log('CHECK AGENT OFFICE INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('agentOffice/getData', query);
        console.log('GET AGENT OFFICE DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
  },
};
</script>
